import { RefObject, useCallback } from 'react';
import { ClipPlayerStore } from '@/stores/playerV2';
import useThrottle from '@/hooks/useThrottle';
import classes from './Transcript.module.scss';

const HIGHLIGHT_PADDING = 10;
let lastWordBeforeDeletedBlock: number | null = null;

export default function useFullClipTranscriptHighlight() {
  const scrollToWord = (highlightedWordElement: HTMLDivElement, container) => {
    if (highlightedWordElement && isWordVisible(highlightedWordElement, container)) {
      highlightedWordElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  const throttledScrollToWord = useThrottle(scrollToWord, 1000);

  const highlightFullClipActiveWord = useCallback(
    (container: React.RefObject<HTMLDivElement>, playerStore: ClipPlayerStore, isScrolling: boolean) => {
      if (!container.current) return;
      const currSrt = playerStore.currentSrtIndex;
      const currTime = playerStore.currentTime;
      container.current!.querySelector(`.${classes['active-word']}`)?.classList.remove(classes['active-word']);
      if (!container || isNaN(currSrt)) return;

      if (!playerStore.paused) {
        const words = container.current!.querySelectorAll(`.srt-index-${currSrt}`);
        let highlightedWordElement;

        (words || []).forEach(word => {
          const { wordStartTime = '0', wordEndTime = '0' } = (word as HTMLDivElement).dataset;
          if (parseFloat(wordStartTime) <= currTime && parseFloat(wordEndTime) >= currTime) {
            highlightedWordElement = word as unknown as HTMLDivElement;
            (highlightedWordElement as HTMLDivElement)?.classList.add(classes['active-word']);
          }
        });

        if (!highlightedWordElement) return;

        // handle highlight of the current word if players skip deleted portion of the transcript
        const wordId = Number.parseInt(highlightedWordElement.getAttribute('data-word-id') || '-1');
        const isWordDeleted = highlightedWordElement.classList.contains('line-through');
        if (isWordDeleted && isWordVisible(highlightedWordElement, container)) {
          lastWordBeforeDeletedBlock = wordId;
        }
        // First non deleted word -> scroll to it
        if (lastWordBeforeDeletedBlock && wordId && wordId !== -1 && wordId > lastWordBeforeDeletedBlock) {
          lastWordBeforeDeletedBlock = null;
          highlightedWordElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        } else if (!isScrolling)
          // Use throttle because clip currentTime change is triggered every 30ms
          throttledScrollToWord(highlightedWordElement, container);
      }
    },
    [throttledScrollToWord]
  );

  return { highlightFullClipActiveWord };
}

function isWordVisible(highlightedWordElement: HTMLDivElement, container: RefObject<HTMLDivElement>): boolean {
  const elementRect = highlightedWordElement.getBoundingClientRect();
  const containerRect = container.current?.getBoundingClientRect();
  return !!(
    containerRect &&
    elementRect.top >= containerRect.top + HIGHLIGHT_PADDING &&
    elementRect.bottom <= containerRect.bottom + HIGHLIGHT_PADDING
  );
}
