import { useState, useSyncExternalStore, useMemo, memo } from 'react';
import { assetsChaptersGenerateCreate, ChapterResponse } from '@goldcast/api/content';
import { core } from '@/stores/core';
import IconButton from '@/components/atoms/Button/IconButton';
import { loadChapters } from '@/context/TranscriptContext/TranscriptContextUtils';
import { useAppContext } from '@/context/AppContext/AppContext';
import { CustomEvents } from '@/libs/eventBus/constants';
import EventBus from '@/libs/eventBus/eventBus';
import poll from '@/libs/polling';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';

const ChaptersView = ({ chapters, totalDuration }: { chapters: ChapterResponse[]; totalDuration: number }) => {
  const [isGeneratingChapters, setIsGeneratingChapters] = useState(false);
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const broadcastId = useMemo(() => coreStore.content?.id, [coreStore.content]);
  const { logger } = useAppContext();

  const generateChapters = async () => {
    if (!broadcastId) return;
    try {
      setIsGeneratingChapters(true);
      await assetsChaptersGenerateCreate({ body: { content_id: broadcastId } } as any);
      startPollingChapters(broadcastId);
    } catch (error) {
      logger.error('Error generating chapters', error);
    }
  };

  const startPollingChapters = (broadcastId: string) => {
    poll({
      pollFunction: async () => {
        const chapters = await loadChapters(broadcastId);
        return chapters && chapters.length > 0 ? chapters : null;
      },
      onSuccess: chapters => {
        EventBus.dispatch(CustomEvents.UpdateChapters, chapters);
        setIsGeneratingChapters(false);
        showSuccessToast('Chapters generated successfully');
      },
      onError: error => {
        logger.error('Error while polling chapters:', error);
        showErrorToast('Error while fetching chapters');
      }
    });
  };

  if (!chapters) return null;

  return (
    <div className="relative h-6 w-full">
      {chapters.length === 0 && (
        <IconButton
          trackingId="generate-chapter"
          icon={isGeneratingChapters ? 'IconLoader2' : 'IconSparkles'}
          iconClassName={isGeneratingChapters ? 'animate-spin' : ''}
          size="small"
          disabled={isGeneratingChapters}
          buttonClassName="h-6 bg-slate-100 btn-text"
          onClick={generateChapters}
          content={isGeneratingChapters ? 'Generating Chapters' : 'Generate Chapters'}
        />
      )}
      {chapters.length > 0 &&
        chapters.map((chapter: ChapterResponse, index: number) => {
          const nextStartTime = chapters[index + 1]?.start_time || totalDuration;
          const widthPercentage = ((nextStartTime - chapter.start_time) / totalDuration) * 100;

          return (
            <div
              key={chapter.id}
              className="group absolute flex h-6 items-center rounded-md pr-1 hover:bg-slate-100"
              style={{
                left: `${(chapter.start_time / totalDuration) * 100}%`,
                width: `${widthPercentage}%`
              }}
            >
              <div className="absolute -left-1 z-20 h-full w-2"></div>
              <div className="absolute -left-[1px] h-3 w-[1.5px] shrink-0 rounded bg-black"></div>
              <div className="group flex h-full grow translate-x-1 cursor-pointer items-center truncate text-2xs">
                <button className="w-full min-w-0 select-none truncate whitespace-nowrap text-left">
                  {chapter.title}
                </button>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(ChaptersView);
