import { useEffect, useState } from 'react';
import { AbsoluteFill, continueRender, delayRender } from 'remotion';
import { loadFont } from '@remotion/fonts';
import { RemotionConfig } from '../types';
import MainTimelineElement from './MainTimelineElement';

const Main: React.FC<RemotionConfig> = ({ elements, font, drift }) => {
  const [handle] = useState(() => delayRender());

  useEffect(() => {
    if (font?.location && font.name) {
      loadFont({
        family: font.name,
        url: font.location
      }).then(() => {
        // eslint-disable-next-line no-console
        console.log('Font loaded - ', font.name);
        continueRender(handle);
      });
    } else {
      continueRender(handle);
    }
  }, [font?.location, font?.name]);

  if (!elements?.length) return null;

  return (
    <AbsoluteFill
      style={{
        inset: 0
      }}
      className="content-lab-root"
    >
      {elements.map((element, index) => {
        if (!element) return null;

        return <MainTimelineElement key={index} element={element} drift={drift} />;
      })}
    </AbsoluteFill>
  );
};

export default Main;
