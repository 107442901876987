import { TranscriptSelectionRange, TranscriptSelectionRanges } from '../types';
import { Word } from '@/domains/transcript';
import { ClipDeletes } from '@/domains/asset';
import { DeletedRange } from '@/stores/clip';

/**
 *
 * @param selectionRanges ranges of words selected in transcript
 * @returns new deletes that are inside active words selected ranges
 */
export function getDeletesFromSelectedRanges(selectionRanges: TranscriptSelectionRanges | null): ClipDeletes {
  return selectionRanges
    ? selectionRanges?.active.reduce((acc: ClipDeletes, el: TranscriptSelectionRange) => {
        return {
          ...acc,
          [el.start]: {
            prev_end_time: el.start,
            next_start_time: el.end,
            bounds: [el.start, el.end]
          }
        };
      }, {})
    : {};
}

/**
 *
 * @param words that we need boundaries for
 * @returns first and last words on the words array
 */
export function getWordsBoundaries(words: Word[]): { firstWord: Word; lastWord: Word } {
  return {
    firstWord: words[0],
    lastWord: words[words.length - 1]
  };
}

/**
 *
 * @param firstSelectedWord from transcript selection
 * @param lastSelectedWord from transcript selection
 * @param clipDeletes from editing clip
 * @returns restoring & keepeing deletes based on selection. In case we select words from
 * multiple different deletes, all deletes would be part of restoring object
 */
export function getSelectionPartitionedDeletes(
  firstSelectedWord: Word,
  lastSelectedWord: Word,
  clipDeletes: ClipDeletes
): {
  restoring: ClipDeletes;
  keeping: ClipDeletes;
} {
  return Object.values(clipDeletes).reduce(
    (acc: { restoring: ClipDeletes; keeping: ClipDeletes }, deleteItem: DeletedRange) => {
      if (lastSelectedWord.end_time >= deleteItem.bounds[0] && firstSelectedWord.start_time <= deleteItem.bounds[1]) {
        acc.restoring[deleteItem.bounds[0]] = deleteItem;
      } else {
        acc.keeping[deleteItem.bounds[0]] = deleteItem;
      }
      return acc;
    },
    { restoring: {}, keeping: {} }
  );
}

/**
 * Merges entries in an object where the `bounds[1]` of one entry matches the `bounds[0]` of another.
 *
 * The function processes the `deletes` object by iterating through its entries,
 * merging consecutive entries that have matching bounds, and returns a new object with merged entries.
 *
 * @param {ClipDeletes} deletes - Deletes of a clip
 *
 * @returns {ClipDeletes} - A new object with merged entries. Keys are based on the `bounds[0]` values of the resulting entries.
 *
 * @example
 * const deletes = {
 *   "0.24": {
 *     "bounds": [0.24, 0.56],
 *     "prev_end_time": 0.24,
 *     "next_start_time": 0.56
 *   },
 *   "0.56": {
 *     "bounds": [0.56, 4],
 *     "prev_end_time": 0.56,
 *     "next_start_time": 4
 *   }
 * };
 *
 * const result = mergeDeletes(deletes);
 * // Output:
 * // {
 * //   "0.24": {
 * //     "bounds": [0.24, 4],
 * //     "prev_end_time": 0.24,
 * //     "next_start_time": 4
 * //   }
 * // }
 */
export function mergeDeletes(deletes: ClipDeletes): ClipDeletes {
  const deletesValues = Object.values(deletes);

  // Sort the entries by their bounds[0] to process them in order
  deletesValues.sort((a, b) => a.bounds[0] - b.bounds[0]);

  const merged: DeletedRange[] = [];

  for (let i = 0; i < deletesValues.length; i++) {
    const current = { ...deletesValues[i] };
    let j = i + 1;

    while (j < deletesValues.length && current.bounds[1] === deletesValues[j].bounds[0]) {
      current.bounds = [current.bounds[0], deletesValues[j].bounds[1]];
      current.next_start_time = deletesValues[j].next_start_time;
      j++;
    }

    merged.push(current);
    i = j - 1;
  }

  return merged.reduce((result, entry) => {
    result[entry.bounds[0]] = entry;
    return result;
  }, {});
}
