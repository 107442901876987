import { CSSProperties } from 'react';

type CompositionOverrideProps = {
  durationInFrames?: number;
  fps?: number;
  height?: number;
  width?: number;
};

export type RemotionConfig = CompositionOverrideProps & {
  elements: TimelineElement[];
  font?: {
    location?: string;
    name?: string;
  };
  scale?: number;
  drift: number;
};

export enum TimelineElementType {
  TEXT = 'TEXT',
  CONTAINER = 'CONTAINER'
}

export interface BaseTimelineElement {
  fromFrame: number;
  toFrame: number;
  style?: CSSProperties;
  type: TimelineElementType;
}

export interface TextTimelineElement extends BaseTimelineElement {
  type: TimelineElementType.TEXT;
  content: string;
}

export interface ContainerTimelineElement extends BaseTimelineElement {
  type: TimelineElementType.CONTAINER;
  elements?: TimelineElement[];
}

export type TimelineElement = TextTimelineElement | ContainerTimelineElement;
