import { init, FeatureFlag } from '@goldcast/feature-flags';
import { getEnvConfig } from '@/constants';
import featureFlagStore from '@/stores/featureFlagStore';

type FeatureFlags = {
  [_ in FeatureFlagKeys]: FeatureFlag;
};

export enum FeatureFlagKeys {
  /* PERMANENT FLAGS */
  Use_CL_AI_Debug_Tools = 'Use_CL_AI_Debug_Tools',
  /* TEMPORARY FLAGS */
  Use_CL_New_Timeline = 'Use_CL_New_Timeline',
  Use_CL_Captions_Overlay = 'Use_CL_Captions_Overlay',
  Use_CL_Facial_Rec_Increment_AB = 'Use_CL_Facial_Rec_Increment_AB',
  Use_CL_Fast_Accurate = 'Use_CL_Fast_Accurate',
  Use_CL_AI_Clips = 'Use_CL_AI_Clips',
  Use_CL_FTUX_Clip_Templates = 'Use_CL_FTUX_Clip_Templates',
  Use_CL_FTUX_Phase2 = 'Use_CL_FTUX_Phase2',
  Use_CL_AI_Social_Posts = 'Use_CL_AI_Social_Posts',
  Use_CL_AI_Search_Source_Select = 'Use_CL_AI_Search_Source_Select',
  Use_CL_Edit_Full_Recording = 'Use_CL_Edit_Full_Recording',
  Use_CL_Edit_Full_Recording_Trim = 'Use_CL_Edit_Full_Recording_Trim',
  Use_CL_Publish_Recording = 'Use_CL_Publish_Recording',
  Use_CL_Clip_Player_V2 = 'Use_CL_Clip_Player_V2',
  Use_CL_AI_Blog_Posts = 'Use_CL_AI_Blog_Posts',
  Use_CL_Chapterization = 'Use_CL_Chapterization',
  Use_CL_Speaker_Labels_Recordings = 'Use_CL_Speaker_Labels_Recordings'
}

export const handleFlagValueChange = (flag: FeatureFlagKeys) => (value: boolean) => {
  featureFlagStore.update(data => ({
    ...data,
    [flag]: value
  }));
};

export const featureFlags = (): FeatureFlags => ({
  [FeatureFlagKeys.Use_CL_AI_Debug_Tools]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Debug_Tools)
  },
  [FeatureFlagKeys.Use_CL_New_Timeline]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_New_Timeline)
  },
  [FeatureFlagKeys.Use_CL_Captions_Overlay]: {
    value: true,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Captions_Overlay)
  },
  [FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Facial_Rec_Increment_AB)
  },
  [FeatureFlagKeys.Use_CL_Fast_Accurate]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Fast_Accurate)
  },
  [FeatureFlagKeys.Use_CL_AI_Clips]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Clips)
  },
  [FeatureFlagKeys.Use_CL_FTUX_Clip_Templates]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_FTUX_Clip_Templates)
  },
  [FeatureFlagKeys.Use_CL_AI_Social_Posts]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Social_Posts)
  },
  [FeatureFlagKeys.Use_CL_FTUX_Phase2]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_FTUX_Phase2)
  },
  [FeatureFlagKeys.Use_CL_AI_Search_Source_Select]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Search_Source_Select)
  },
  [FeatureFlagKeys.Use_CL_Edit_Full_Recording]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Edit_Full_Recording)
  },
  [FeatureFlagKeys.Use_CL_Clip_Player_V2]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Clip_Player_V2)
  },
  [FeatureFlagKeys.Use_CL_AI_Blog_Posts]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_AI_Blog_Posts)
  },
  [FeatureFlagKeys.Use_CL_Edit_Full_Recording_Trim]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Edit_Full_Recording_Trim)
  },
  [FeatureFlagKeys.Use_CL_Publish_Recording]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Publish_Recording)
  },
  [FeatureFlagKeys.Use_CL_Chapterization]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Chapterization)
  },
  [FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings]: {
    value: false,
    changeHandler: handleFlagValueChange(FeatureFlagKeys.Use_CL_Speaker_Labels_Recordings)
  }
});

// store promise in var for cases of calling this function from multiple places
let initializationPromise: Promise<void> | null = null;

export const initializeFeatureFlags = user => {
  if (featureFlagStore.getSnapshot().areFlagsLoaded) return Promise.resolve();
  if (!initializationPromise) {
    initializationPromise = new Promise<void>((resolve, reject) => {
      init(
        getEnvConfig('APP_HARNESS_SECRET') || '',
        {
          identifier: user.id || 'anonymous',
          name: `${user.first_name} ${user.last_name}` || 'anonymous',
          attributes: {
            role: 2, // admin role
            event: '',
            eventOrganization: user.organization,
            is_content_lab_standalone: user.is_content_lab_standalone,
            appHostname: window.location?.hostname
          }
        },
        featureFlags(),
        {
          ready: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return resolve();
          },
          errored: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return reject();
          },
          errorAuth: () => {
            featureFlagStore.update(data => ({ ...data, areFlagsLoaded: true }));
            return reject();
          }
        }
      );
    });
  }
  return initializationPromise;
};
